import {
  anyToFWPersonGroup,
  anyToUDMPerson,
  FWPersonGroup,
  FWRegisterCriteria,
  FWRegisterCriteriaChoice,
  FWRegisterCriteriaCorrectiveAction,
  FWRegisterRecordEntry,
  UDMPerson,
} from "foodware-lib";
import { arrowBack, close, cloudDoneOutline } from "ionicons/icons";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTextarea,
  IonTitle,
  IonToolbar,
  useIonAlert,
  useIonToast,
} from "@ionic/react";

import { FWBackendApi } from "../../services/FWBackendApi";
import { ButtonRef, RegisterRecordCollectProps } from "../types";

const defaultCloseHandler = () => {
  console.log("Calling default close handler");
};

const defaultRegisterRecordEntryCreateHandler = (
  data: Record<string, unknown>
) => {
  console.log(
    "Calling default register entry creation handler with data: " + data
  );
  return Promise.resolve(null);
};

const RegisterRecordCollectPeople: React.FC<RegisterRecordCollectProps> = ({
  readOnly = false,
  closeHandler = defaultCloseHandler,
  newRegisterRecordEntryCreateHandler = defaultRegisterRecordEntryCreateHandler,
  registerRecord,
  registerFormat,
  refetch = () => Promise.resolve(null),
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [closeRegisterRecord] = FWBackendApi.useCloseRegisterRecordMutation();
  const [cancelRegisterRecord] = FWBackendApi.useCancelRegisterRecordMutation();

  /****** CRITERIA VS PERSONS REGISTER FORMATS ******/
  const [selectedPerson, setSelectedPerson] = useState<UDMPerson>();
  const [selectedPersonGroup, setSelectedPersonGroup] =
    useState<FWPersonGroup>();
  const selectedPersonName = `${selectedPerson?.firstName} ${selectedPerson?.lastName}`;

  const goBackToListing = (withRefetch = false) => {
    const refetchPromise = withRefetch ? refetch() : Promise.resolve(null);
    refetchPromise.then(() => {
      setSelectedPerson(undefined);
      setCurrentStep(0);
    });
  };

  /****** PERSON GROUPS TO CHOOSE ******/
  const {
    data: personGroupsDataRaw,
    isFetching: registerFormatsIsFetching,
    isLoading: registerFormatsIsLoading,
    isSuccess: registerFormatsIsSuccess,
    // isError: registerFormatsIsError,
  } = FWBackendApi.useGetAllPersonGroupsQuery({});
  const personGroupsData = (personGroupsDataRaw || []).map(anyToFWPersonGroup);

  /****** WORKERS IN THE CHOSEN PERSON GROUP ******/
  const {
    data: personGroupWorkersRaw,
    isFetching: workersIsFetching,
    isLoading: workersIsLoading,
    isSuccess: workersIsSuccess,
  } = FWBackendApi.useGetAllPersonGroupPersonsQuery(
    {
      groupId: selectedPersonGroup && selectedPersonGroup?.id,
      registerFormatId: registerFormat?.id,
    },
    { skip: !selectedPersonGroup }
  );
  const personGroupWorkers = (personGroupWorkersRaw || []).map(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (pgw: { person: any }) => anyToUDMPerson(pgw.person.person)
  );

  const registerRecordPersonEntries = (registerRecord?.entries || []).map(
    (e: FWRegisterRecordEntry) => e.person?.id
  );

  const personHasRecordEntry = (personId: string) =>
    registerRecordPersonEntries.indexOf(personId) > -1;

  /****** CRITERIA MANAGEMENT ******/
  const criteria = (registerFormat?.criteria || []).sort(
    (a: FWRegisterCriteria, b: FWRegisterCriteria) => a.order - b.order
  );

  const [criteriaState, setCriteriaState] = useState<Record<string, boolean>>(
    {}
  );
  const setCriteriaStateAt = (criteriaId: string, value: boolean) => {
    const newCriteriaState: Record<string, boolean> = { ...criteriaState };
    newCriteriaState[criteriaId] = value;
    setCriteriaState(newCriteriaState);
  };

  type FormCriteriaSchema = {
    validator: { name: string; value: unknown };
    correctiveValidator: { name: string; value: unknown };
    correctiveValidatorComments: { name: string; value: unknown };
    criteria: FWRegisterCriteria;
  };

  const formSchemaEntries: FormCriteriaSchema[] = criteria.map(
    (c: FWRegisterCriteria) => {
      return {
        criteria: c,
        validator: {
          name: `criteria_${c.id}`,
          value: yup.string().required("Debe indicar opción."),
        },
        correctiveValidator: {
          name: `criteria_${c.id}_corrective`,
          value: yup.string().when(`criteria_${c.id}`, {
            is: false,
            then: (schema) =>
              schema.required("Debe indicar acción correctiva."),
          }),
        },
        correctiveValidatorComments: {
          name: `criteria_${c.id}_comments`,
          value: yup.string().when(`criteria_${c.id}`, {
            is: false,
            then: (schema) =>
              schema.required(
                "Debe indicar comentarios como acción correctiva."
              ),
          }),
        },
      };
    }
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formSchemaObject: Record<string, any> = {};
  for (const entry of formSchemaEntries) {
    formSchemaObject[entry.validator.name] = entry.validator.value;

    /* Make comments mandatory for non-compliance with no predefined
    corrective actions */
    if (entry.criteria.correctiveActions.length == 0) {
      formSchemaObject[entry.correctiveValidatorComments.name] =
        entry.correctiveValidatorComments.value;
    } else {
      formSchemaObject[entry.correctiveValidator.name] =
        entry.correctiveValidator.value;
    }
  }

  const formSchema = yup.object({ ...formSchemaObject });
  const initialValues = {
    registerRecordId: registerRecord?.id,
    personId: selectedPerson?.id,
  };

  const {
    handleSubmit,
    setValue,
    register,
    reset,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
    mode: "onChange",
  });

  /*
   Things to do when a new person is selected:
    - Reset form state.
      - To empty/default values if no record is present.
      - To currently saved values, for update.
    - Check whether there is a record entry for the person already.
      - Update form values to reflect the current values registered.
      - Add flag for submit handling to know that this is an update.
  */

  useEffect(() => {
    reset((_formValues) => ({
      ...initialValues,
    }));
  }, [selectedPerson]);

  const [presentToast] = useIonToast();
  const [presentAlert] = useIonAlert();

  const radioButtonRefs: Record<string, Record<string, ButtonRef>> = {};
  if (criteria) {
    for (const c of criteria) {
      const criteriaId = `criteria_${c.id}`;
      const criteriaButtonRefs: Record<string, ButtonRef> = {};
      for (const choice of c.valueChoices) {
        criteriaButtonRefs[choice.id] = {
          button_ref: useRef(null),
          is_success: choice.is_success,
          is_error: choice.is_error,
        };
      }
      radioButtonRefs[criteriaId] = criteriaButtonRefs;
    }
  }

  const hasErrors = () => Object.keys(errors).length > 0;

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!registerFormatsIsSuccess && registerFormatsIsFetching) {
      setLoading(true);
    } else if (registerFormatsIsSuccess) {
      setLoading(false);
    }

    if (!workersIsSuccess && workersIsFetching) {
      setLoading(true);
    } else if (workersIsSuccess) {
      setLoading(false);
    }
  }, [
    registerFormatsIsFetching,
    registerFormatsIsLoading,
    registerFormatsIsSuccess,
    workersIsFetching,
    workersIsLoading,
    workersIsSuccess,
  ]);

  console.log(`Loading ${loading}`);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            {currentStep === 0 && (
              <IonButton onClick={closeHandler}>
                <IonIcon slot="icon-only" icon={close}></IonIcon>
              </IonButton>
            )}
            {currentStep === 1 && (
              <IonButton onClick={() => goBackToListing()}>
                <IonIcon slot="icon-only" icon={arrowBack}></IonIcon>
              </IonButton>
            )}
          </IonButtons>
          <IonTitle>{registerRecord && registerRecord.title}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <>
        {/* ***************************** STEP 0 ***************************** */}
        {registerRecord && registerFormat && currentStep === 0 && (
          <IonContent fullscreen>
            <IonLoading isOpen={loading} />
            {!readOnly && (
              <>
                <IonCard>
                  <IonCardContent>
                    <h2>
                      Seleccione un grupo{" "}
                      {selectedPersonGroup && selectedPersonGroup?.id}
                    </h2>
                    <IonSelect
                      disabled={readOnly}
                      placeholder="Seleccionar grupo"
                      value={selectedPersonGroup}
                      compareWith={(p1: UDMPerson, p2: UDMPerson) =>
                        p1.id === p2.id
                      }
                      onIonChange={(evt) => {
                        setSelectedPersonGroup(evt.detail.value);
                      }}
                    >
                      {(personGroupsData || [])
                        .sort((a: FWPersonGroup, b: FWPersonGroup) => {
                          return `${a.name} ${a.name}`
                            .toLowerCase()
                            .localeCompare(`${b.name} ${b.name}`.toLowerCase());
                        })
                        .map((g: FWPersonGroup) => {
                          return (
                            <IonSelectOption key={g.id} value={g}>
                              {g.name}
                            </IonSelectOption>
                          );
                        })}
                    </IonSelect>
                  </IonCardContent>
                </IonCard>
                <IonCard>
                  <IonCardContent>
                    <h2>Seleccione al personal</h2>
                    <IonSelect
                      disabled={readOnly || !selectedPersonGroup}
                      placeholder="Seleccionar persona"
                      value={selectedPerson}
                      compareWith={(p1: UDMPerson, p2: UDMPerson) =>
                        p1.id === p2.id
                      }
                      onIonChange={(evt) => {
                        setSelectedPerson(evt.detail.value);
                      }}
                    >
                      {(personGroupWorkers || [])
                        .filter((w: UDMPerson) => !personHasRecordEntry(w.id))
                        .sort((a: UDMPerson, b: UDMPerson) => {
                          return `${a.firstName} ${a.lastName}`
                            .toLowerCase()
                            .localeCompare(
                              `${b.firstName} ${b.lastName}`.toLowerCase()
                            );
                        })
                        .map((w: UDMPerson) => {
                          return (
                            <IonSelectOption key={w.id} value={w}>
                              {w.firstName} {w.lastName}
                            </IonSelectOption>
                          );
                        })}
                    </IonSelect>
                  </IonCardContent>
                  <IonButton
                    className="ion-float-right"
                    fill="clear"
                    disabled={!selectedPerson}
                    onClick={() => setCurrentStep(1)}
                  >
                    Continuar
                  </IonButton>
                </IonCard>
              </>
            )}

            <IonCard>
              <IonCardHeader>
                <IonCardSubtitle>Entradas ingresadas</IonCardSubtitle>
              </IonCardHeader>
              <IonCardContent>
                <IonList>
                  {registerFormat &&
                    registerFormat.workers
                      .filter((w: UDMPerson) => personHasRecordEntry(w.id))
                      .sort((a, b) =>
                        a.firstName
                          .toLowerCase()
                          .localeCompare(b.firstName.toLowerCase())
                      )
                      .map((w: UDMPerson) => {
                        return (
                          <IonItem button key={w.id}>
                            <IonIcon icon={cloudDoneOutline} slot="start" />
                            <IonLabel>
                              {w.firstName} {w.lastName}
                            </IonLabel>
                          </IonItem>
                        );
                      })}
                </IonList>
              </IonCardContent>
            </IonCard>
            <>
              {!readOnly && (
                <IonButton
                  type="submit"
                  expand="full"
                  onClick={() => {
                    presentAlert({
                      header: "Confirma",
                      subHeader: "¿Desea finalizar y guardar este registro?",
                      message:
                        "Si lo haces, ya no podrás ingresar o editar más datos en este registro.",
                      buttons: [
                        {
                          text: "Finalizar Registro",
                          role: "confirm",
                          handler: () => {
                            console.log("Confirm");
                            closeRegisterRecord(registerRecord.id)
                              .unwrap()
                              .then(() => {
                                closeHandler();
                              })
                              .catch((error: unknown) => console.error(error));
                          },
                        },
                        {
                          text: "Cancelar",
                          role: "cancel",
                        },
                      ],
                    });
                  }}
                >
                  Finalizar Registro
                </IonButton>
              )}

              {!readOnly && (
                <IonButton
                  type="submit"
                  color="danger"
                  expand="full"
                  onClick={() => {
                    presentAlert({
                      header: "Confirma",
                      subHeader: "¿Desea cancelar este registro?",
                      message:
                        "Si lo haces, no quedará guardado ningún dato que haya sido ingresado a este registro.",
                      buttons: [
                        {
                          text: "Cancelar Registro",
                          role: "confirm",
                          handler: () => {
                            console.log("Confirm");
                            cancelRegisterRecord(registerRecord.id)
                              .unwrap()
                              .then(() => {
                                closeHandler();
                              })
                              .catch((error: unknown) => console.error(error));
                          },
                        },
                        {
                          text: "No cancelar",
                          role: "cancel",
                        },
                      ],
                    });
                  }}
                >
                  Cancelar Registro
                </IonButton>
              )}
            </>
          </IonContent>
        )}
      </>

      {/* ***************************** STEP 1 ***************************** */}
      {registerRecord &&
        registerFormat &&
        currentStep === 1 &&
        selectedPerson && (
          <IonContent>
            <IonCard
              className={
                "ion-no-padding ion-no-border ion-no-margin ion-text-center"
              }
              style={{
                position: "sticky",
                top: 0,
                width: "100%",
                zIndex: "9999",
              }}
            >
              <IonCardContent>
                <IonText style={{ fontWeight: 700 }}>
                  Creando registro para {selectedPerson?.firstName}{" "}
                  {selectedPerson?.lastName}
                </IonText>

                <div>
                  {hasErrors() && (
                    <IonText style={{ color: "red" }}>
                      Faltan criterios por completar
                    </IonText>
                  )}
                </div>
              </IonCardContent>

              <div className="ion-text-center">
                <IonButton
                  size="small"
                  fill="outline"
                  onClick={() => {
                    console.log("Cumple todo");
                    for (const c of criteria) {
                      const criteriaId = `criteria_${c.id}`;
                      const successButtons = [];
                      for (const entry of Object.entries(
                        radioButtonRefs[criteriaId]
                      )) {
                        console.log("entry:");
                        console.log(entry);
                        if (entry[1].is_success) {
                          successButtons.push(entry[1].button_ref?.current);
                        }
                      }
                      for (const button of successButtons) {
                        if (button) {
                          button.click();
                        }
                      }
                    }
                  }}
                >
                  Cumple todo
                </IonButton>
              </div>
            </IonCard>
            <IonCard>
              <IonCardContent>
                <form>
                  <IonGrid className={"ion-no-padding"}>
                    <IonRow>
                      <IonCol className={"ion-text-center"}>
                        <IonText
                          style={{ fontWeight: "700", fontSize: "1.1rem" }}
                        >
                          Criterios
                        </IonText>
                      </IonCol>
                    </IonRow>
                    {criteria.map((c: FWRegisterCriteria) => {
                      return (
                        <IonRow key={c.id}>
                          <IonCol>
                            <IonCard className="register-criteria-card">
                              <IonCardHeader>
                                <IonCardTitle>{c.name}</IonCardTitle>
                              </IonCardHeader>

                              <IonRadioGroup
                                {...register(`criteria_${c.id}`)}
                                onIonChange={(evt) => {
                                  console.log(`Change: ${evt.detail.value}`);

                                  const chosenChoice = c.valueChoices.find(
                                    (choice) => choice.id === evt.detail.value
                                  );
                                  console.log("Chosen Choice");
                                  console.log(chosenChoice);
                                  if (chosenChoice) {
                                    setCriteriaStateAt(
                                      `${c.id}`,
                                      chosenChoice.is_error
                                    );
                                    setValue(
                                      `criteria_${c.id}`,
                                      chosenChoice.id
                                    );
                                  }
                                }}
                              >
                                {/* RENDER VALUE CHOICES */}
                                {(c.valueChoices || []).map(
                                  (choice: FWRegisterCriteriaChoice) => {
                                    return (
                                      <IonItem lines="none" key={choice.id}>
                                        <IonLabel
                                          onClick={() => {
                                            const button =
                                              radioButtonRefs[
                                                `criteria_${c.id}`
                                              ][c.id]["button_ref"]?.current;

                                            if (button) {
                                              button.click();
                                            }
                                          }}
                                        >
                                          {choice.value}
                                        </IonLabel>
                                        <IonRadio
                                          ref={
                                            radioButtonRefs[`criteria_${c.id}`][
                                              choice.id
                                            ][
                                              "button_ref"
                                            ] as React.RefObject<HTMLIonRadioElement>
                                          }
                                          color={
                                            choice.is_error
                                              ? "danger"
                                              : choice.is_success
                                              ? "success"
                                              : "primary"
                                          }
                                          value={choice.id}
                                          slot="start"
                                        ></IonRadio>
                                      </IonItem>
                                    );
                                  }
                                )}
                              </IonRadioGroup>

                              {criteriaState &&
                                `${c.id}` in criteriaState &&
                                criteriaState[`${c.id}`] &&
                                c.correctiveActions.length > 0 && (
                                  <IonItem>
                                    <IonLabel position="stacked">
                                      Acción Correctiva
                                    </IonLabel>
                                    <IonSelect
                                      {...register(
                                        `criteria_${c.id}_corrective`
                                      )}
                                      onIonChange={(evt) => {
                                        setValue(
                                          `criteria_${c.id}_corrective`,
                                          evt.detail.value
                                        );
                                      }}
                                    >
                                      {c.correctiveActions.map(
                                        (
                                          ca: FWRegisterCriteriaCorrectiveAction
                                        ) => {
                                          return (
                                            <IonSelectOption
                                              key={ca.id}
                                              value={ca.id}
                                            >
                                              <IonLabel>{ca.name}</IonLabel>
                                            </IonSelectOption>
                                          );
                                        }
                                      )}
                                    </IonSelect>
                                  </IonItem>
                                )}
                              <IonItem>
                                <IonLabel position="stacked">
                                  Comentarios
                                </IonLabel>
                                <IonTextarea
                                  {...register(`criteria_${c.id}_comments`)}
                                  placeholder="Ingrese sus comentarios"
                                  autoGrow
                                ></IonTextarea>
                              </IonItem>

                              <ErrorMessage
                                errors={errors}
                                name={`criteria_${c.id}`}
                                render={({ message }) => (
                                  <IonItem>
                                    <IonText style={{ color: "red" }}>
                                      {message}
                                    </IonText>
                                  </IonItem>
                                )}
                              />

                              <ErrorMessage
                                errors={errors}
                                name={`criteria_${c.id}_corrective`}
                                render={({ message }) => (
                                  <IonItem>
                                    <IonText style={{ color: "red" }}>
                                      {message}
                                    </IonText>
                                  </IonItem>
                                )}
                              />

                              <ErrorMessage
                                errors={errors}
                                name={`criteria_${c.id}_comments`}
                                render={({ message }) => (
                                  <IonItem>
                                    <IonText style={{ color: "red" }}>
                                      {message}
                                    </IonText>
                                  </IonItem>
                                )}
                              />
                            </IonCard>
                          </IonCol>
                        </IonRow>
                      );
                    })}
                  </IonGrid>
                </form>

                <IonButton
                  type="submit"
                  expand="full"
                  onClick={async () => {
                    console.log("Submitting...");
                    const isValid = await trigger();

                    if (isValid) {
                      presentAlert({
                        header: "Confirma",
                        subHeader: "¿Desea guardar estos datos?",
                        message: "Si lo haces, ya no podrás editarlos.",
                        buttons: [
                          {
                            text: "Guardar datos",
                            role: "confirm",
                            handler: () => {
                              console.log("Confirmar");
                              handleSubmit((data) => {
                                console.log("Handling submit");
                                console.log(data);
                                newRegisterRecordEntryCreateHandler({
                                  ...data,
                                  personId: initialValues?.personId,
                                })
                                  .then(() => {
                                    presentToast({
                                      duration: 3000,
                                      message: `Se registraron correctamente los datos para ${
                                        selectedPerson && selectedPersonName
                                      }`,
                                      position: "top",
                                      buttons: [
                                        {
                                          text: "OK",
                                          role: "cancel",
                                        },
                                      ],
                                    }).then(() => goBackToListing(true));
                                  })
                                  .catch((error) => {
                                    console.error(error);
                                    presentToast({
                                      duration: 3000,
                                      message: `Error al tratar de registrar los datos. Por favor intenta nuevamente más tarde.`,
                                      position: "top",
                                      buttons: [
                                        {
                                          text: "OK",
                                          role: "cancel",
                                        },
                                      ],
                                    });
                                  });
                              })();
                            },
                          },
                          {
                            text: "No guardar",
                            role: "cancel",
                          },
                        ],
                      });
                    }
                  }}
                >
                  Registrar
                </IonButton>
              </IonCardContent>
            </IonCard>
          </IonContent>
        )}
    </IonPage>
  );
};

export default RegisterRecordCollectPeople;
