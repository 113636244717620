import "./Registers.css";

import {
  anyToFWRegisterRecord,
  anyToFWUserProfile,
  FWRegisterFormat,
  FWRegisterRecord,
  FWUserProfile
} from "foodware-lib";
import * as O from "fp-ts/Option";
import React, { useEffect, useState } from "react";

import {
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonImg,
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonList,
  IonLoading,
  IonMenu,
  IonMenuButton,
  IonMenuToggle,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonText,
  IonTitle,
  IonToolbar,
  useIonModal
} from "@ionic/react";

import {
  RegisterRecordCollectWrapper,
  RegisterRecordCollectWrapperProps
} from "../components/RegisterRecordCollect";
import { logoutUser } from "../features/user/userActions";
import { useAppDispatch, useAppSelector } from "../hooks";
import { FWBackendApi } from "../services/FWBackendApi";
import { FWProfileApi } from "../services/FWProfileApi";

import type { RootState } from "../store";

const NewRegisterCollectModal = ({
  registerRecordCollectWrapperProps,
}: {
  registerRecordCollectWrapperProps: RegisterRecordCollectWrapperProps;
}) => <RegisterRecordCollectWrapper {...registerRecordCollectWrapperProps} />;

const Registers: React.FC = () => {
  const dispatch = useAppDispatch();

  const { userInfo, loggedIn } = useAppSelector(
    (state: RootState) => state.user
  );
  const userId = O.isSome(userInfo) ? userInfo.value["pk"] : null;

  const {
    data: rawProfileData,
    error: _profileError,
    isFetching: profileIsFetching,
    isSuccess: profileIsSuccess,
  } = FWProfileApi.endpoints.getProfileByUserId.useQuery(userId, {
    skip: !loggedIn,
  });

  console.log("rawProfileData:");
  console.log(rawProfileData);

  const profileData: FWUserProfile =
    rawProfileData && anyToFWUserProfile(rawProfileData);

  console.log("ProfileData:");
  console.log(profileData);

  const performLogout = async () => {
    console.log("Logging out");
    dispatch(logoutUser({}));
  };

  const [isProfileLoading, setIsProfileLoading] = useState(false);
  useEffect(() => {
    if (!profileIsSuccess && profileIsFetching) {
      setIsProfileLoading(true);
    } else if (profileIsSuccess) {
      setIsProfileLoading(false);
    }
  }, [profileIsFetching, profileIsSuccess]);

  const {
    data: registerRecordsDataRaw,
    isFetching: registerIsFetching,
    isSuccess: registerIsSuccess,
    isError: registerIsError,
    refetch: registerRecordsRefetch,
  } = FWBackendApi.useGetOpenRegisterRecordsFastQuery({});

  const [isRecordsLoading, setIsRecordsLoading] = useState(false);
  useEffect(() => {
    if (registerIsError) {
      setIsRecordsLoading(false);
    } else if (!registerIsSuccess && registerIsFetching) {
      setIsRecordsLoading(true);
    } else if (registerIsSuccess) {
      setIsRecordsLoading(false);
    }
  }, [registerIsFetching, registerIsSuccess, registerIsError]);

  const registerRecordsData: FWRegisterRecord[] = (
    registerRecordsDataRaw || []
  ).map(anyToFWRegisterRecord);

  console.log("registerRecordsData");
  console.log(registerRecordsData);

  const [selectedRegisterRecord, setSelectedRegisterRecord] =
    useState<FWRegisterRecord>();

  const [selectedRegisterFormat, setSelectedRegisterFormat] =
    useState<FWRegisterFormat>();

  const [collectRegisterRecordEntry] =
    FWBackendApi.useCollectRegisterRecordEntryMutation();

  /* TODO: load register format from detail endpoint rather than   
  loading everything in the listing. This way we can use a faster
  serializer in the backend to retrieve the list of active records.
  */
  const [presentNewCollectModal, dismissNewCollectModal] = useIonModal(
    NewRegisterCollectModal,
    {
      registerRecordCollectWrapperProps: {
        closeHandler: () => dismissNewCollectModal(null, undefined),
        newRegisterRecordEntryCreateHandler: (
          data: Record<string, unknown>
        ) => {
          console.log("New entry with data: ");
          console.log(data);
          return collectRegisterRecordEntry(data).unwrap();
        },
        registerRecordId: selectedRegisterRecord?.id,
        // TODO: here pass only the register format id
        registerFormat: selectedRegisterFormat,
      },
    }
  );

  return (
    <>
      <IonMenu contentId="registers">
        <IonHeader>
          <IonToolbar>
            <IonTitle></IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <IonList>
            <IonMenuToggle>
              <IonItem onClick={performLogout}>
                <IonLabel>Cerrar Sesión</IonLabel>
              </IonItem>
            </IonMenuToggle>
          </IonList>
        </IonContent>
      </IonMenu>
      <IonPage id="registers">
        <IonLoading
          backdropDismiss={true}
          isOpen={isProfileLoading || isRecordsLoading}
        ></IonLoading>

        <IonRefresher
          slot="fixed"
          onIonRefresh={async (evt) => {
            await registerRecordsRefetch();
            evt.detail.complete();
          }}
        >
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>

        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton></IonMenuButton>
            </IonButtons>
            <IonTitle>Registros en Progreso</IonTitle>
          </IonToolbar>
        </IonHeader>
        {!isProfileLoading && (
          <IonContent fullscreen className="ion-padding">
            {(registerRecordsData || []).length > 0 && (
              <>
                <IonCard>
                  <IonCardContent>
                    <IonText style={{ fontWeight: 700, color: "black" }}>
                      Tienes {(registerRecordsData || []).length} tomas de
                      registro en progreso
                    </IonText>
                  </IonCardContent>
                </IonCard>
                <IonCard>
                  <IonItemGroup>
                    {(registerRecordsData || []).map((r: FWRegisterRecord) => {
                      return (
                        <>
                          <IonItem
                            key={r.id}
                            button
                            detail={true}
                            lines="none"
                            onClick={() => {
                              setSelectedRegisterRecord(r);
                              setSelectedRegisterFormat(r.registerFormat);
                              presentNewCollectModal();
                            }}
                          >
                            <IonLabel style={{ whiteSpace: "normal" }}>
                              <IonText style={{ fontWeight: 700 }}>
                                #{r.id} {r.title}
                              </IonText>
                              <h2>{r.registerFormat.name}</h2>
                              <h2>(versión {r.registerFormat.version})</h2>
                              {r?.entriesCount && (
                                <h2>{r.entriesCount} entradas ingresadas</h2>
                              )}
                            </IonLabel>
                          </IonItem>
                          <IonItemDivider></IonItemDivider>
                        </>
                      );
                    })}
                  </IonItemGroup>
                </IonCard>
              </>
            )}
            {(registerRecordsData || []).length === 0 && (
              <>
                <IonCard>
                  <IonCardContent>
                    <IonText>
                      Aún no tienes tomas de registro en progreso
                    </IonText>
                  </IonCardContent>
                </IonCard>
                <IonImg
                  src="assets/images/undraw_no_data.png"
                  style={{ margin: "auto" }}
                />
              </>
            )}
          </IonContent>
        )}
      </IonPage>
    </>
  );
};

export default Registers;
