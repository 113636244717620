import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import {
  BACKEND_BASE_URL,
  BACKEND_DRF_BASE_PATH,
  BACKEND_NINJA_BASE_PATH,
  defaultPrepareHeadersAuth,
  makeBaseQueryWithReauthAndRetry,
} from "./common";

interface ListResponse<T> {
  count: number;
  next: string;
  previous: string;
  total: number;
  results: T[];
}

export const FWBackendApi = createApi({
  reducerPath: "FWBackendApi",
  baseQuery: makeBaseQueryWithReauthAndRetry(
    fetchBaseQuery({
      baseUrl: BACKEND_BASE_URL,
      prepareHeaders: defaultPrepareHeadersAuth,
    })
  ),
  tagTypes: [
    "RegisterFormat",
    "RegisterRecord",
    "RegisterRecordEntry",
    "RegisterRecordDownload",
    "Product",
    "PersonGroup",
    "PersonGroupPerson",
  ],
  endpoints: (builder) => ({
    getAllRegisterFormats: builder.query({
      query: () => `/${BACKEND_DRF_BASE_PATH}/register-formats/`,
      providesTags: ["RegisterFormat"],
    }),
    getRegisterFormatById: builder.query({
      query: (registerFormatId) =>
        `/${BACKEND_DRF_BASE_PATH}/register-formats/${registerFormatId}/`,
      providesTags: ["RegisterFormat"],
    }),
    createRegisterRecord: builder.mutation({
      query: ({ registerFormatId, title }) => ({
        url: `/${BACKEND_DRF_BASE_PATH}/register-records-create/`,
        method: "POST",
        body: {
          register_format: registerFormatId,
          title,
        },
      }),
      invalidatesTags: ["RegisterRecord"],
      extraOptions: { maxRetries: 0 },
    }),
    getRegisterRecordById: builder.query({
      query: (registerRecordId) =>
        `/${BACKEND_DRF_BASE_PATH}/register-records-read/${registerRecordId}/`,
      providesTags: ["RegisterRecord"],
    }),
    getOpenRegisterRecords: builder.query({
      query: () => `/${BACKEND_DRF_BASE_PATH}/register-records-read/`,
      providesTags: ["RegisterRecord"],
    }),
    getOpenRegisterRecordsFast: builder.query({
      query: () => `/${BACKEND_DRF_BASE_PATH}/register-records-read-fast/`,
      providesTags: ["RegisterRecord"],
    }),
    getArchivedRegisterRecords: builder.query<
      ListResponse<unknown>,
      number | void
    >({
      query: (page = 1) =>
        `/${BACKEND_DRF_BASE_PATH}/register-records-read-archived/?page=${page}`,
      providesTags: ["RegisterRecord"],
    }),
    downloadRegisterRecord: builder.mutation({
      async queryFn(
        { registerRecordId, registerRecordName },
        _api,
        _extraOptions,
        baseQuery
      ) {
        const result = await baseQuery({
          url: `/${BACKEND_NINJA_BASE_PATH}/download-register-record/${registerRecordId}/`,
          responseHandler: (response) => response.blob(),
          cache: "no-cache",
        });

        const hiddenElement = document.createElement("a");
        const url = window.URL || window.webkitURL;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const blobPDF = url.createObjectURL((result as any).data);
        hiddenElement.href = blobPDF;
        hiddenElement.target = "_blank";
        hiddenElement.download = `Registros_${registerRecordName}.xlsx`;
        hiddenElement.click();
        return { data: null };
      },
      extraOptions: { maxRetries: 0 },
    }),
    collectRegisterRecordEntry: builder.mutation({
      query: ({ registerRecordId, ...data }) => ({
        url: `/${BACKEND_NINJA_BASE_PATH}/collect-register-record-entry/${registerRecordId}/`,
        method: "POST",
        body: data,
      }),
      extraOptions: { maxRetries: 0 },
      invalidatesTags: ["RegisterRecord"],
    }),
    closeRegisterRecord: builder.mutation({
      query: (registerRecordId) => ({
        url: `/${BACKEND_NINJA_BASE_PATH}/submit-register-record/${registerRecordId}/`,
        method: "POST",
      }),
      extraOptions: { maxRetries: 0 },
      invalidatesTags: ["RegisterRecord"],
    }),
    updateRegisterRecordPreamble: builder.mutation({
      query: ({ registerRecordId, preambleData }) => ({
        url: `/${BACKEND_NINJA_BASE_PATH}/update-register-record-preamble/${registerRecordId}/`,
        method: "POST",
        body: preambleData,
      }),
      extraOptions: { maxRetries: 0 },
      invalidatesTags: ["RegisterRecord"],
    }),
    cancelRegisterRecord: builder.mutation({
      query: (registerRecordId) => ({
        url: `/${BACKEND_NINJA_BASE_PATH}/cancel-register-record/${registerRecordId}/`,
        method: "POST",
      }),
      extraOptions: { maxRetries: 0 },
      invalidatesTags: ["RegisterRecord"],
    }),
    deleteRegisterRecordEntry: builder.mutation({
      query: (registerRecordEntryId) => ({
        url: `/${BACKEND_NINJA_BASE_PATH}/delete-register-record-entry/${registerRecordEntryId}/`,
        method: "POST",
      }),
      extraOptions: { maxRetries: 0 },
      invalidatesTags: ["RegisterRecord"],
    }),
    getAllProducts: builder.query({
      query: () => `/${BACKEND_DRF_BASE_PATH}/products/`,
      providesTags: ["Product"],
    }),
    getProduct: builder.query({
      query: (productId) => `/${BACKEND_DRF_BASE_PATH}/products/${productId}/`,
      providesTags: ["Product"],
    }),
    getAllPersonGroups: builder.query({
      query: () => `/${BACKEND_DRF_BASE_PATH}/person-groups/`,
      providesTags: ["PersonGroup"],
    }),
    getAllPersonGroupPersons: builder.query({
      query: ({ groupId, registerFormatId }) =>
        `/${BACKEND_DRF_BASE_PATH}/person-group-workers/?group=${groupId}&register_format=${registerFormatId}`,
      providesTags: ["PersonGroupPerson"],
    }),
  }),
});
